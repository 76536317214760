.dashboard {
    display: flex;
    flex-wrap: wrap;
}
.dashboard .card {
    padding: 10px;
    margin: 2%;
    border: 1px solid #ccc;
}

h2 { font-size: 1.5em; }
h3 { font-size: 1.3em; }

@media (max-width:639px) {
    .dashboard .card {
        flex-basis: 100%;
    }
}

@media (min-width: 640px){
    .dashboard .card {
        flex-basis: 40%;
    } 
}